@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

/* @font-face {
  font-family: 'PPGatwickBold';
  src: url('./assets/PPGatwick-Bold.woff') format('woff'),
       url('./assets/PPGatwick-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Rae';
  src: url('./assets/Rae.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
